<script>
import { inventoryService } from "../../../helpers/backend/inventory.service"
/**
 * Transactions component
 */
export default {
  data() {
    return {
      data: [],
      model_filter: {
        location_id: null,
      },
      filter_type: "",
      location_type_options: [
        { text: "Machine", value: "machine" },
        { text: "Warehouse", value: "warehouse" },
        { text: "Vehicle", value: "vehicle" },
      ],
      location_options: {
        machine: [{ text: "Select One", value: null }],
        warehouse: [{ text: "Select One", value: null }],
        vehicle: [{ text: "Select One", value: null }],
      },
      loading: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "id", sortable: true, label: "id" },
        { key: "sku", sortable: true, label: "Product SKU" },
        { key: "name", sortable: true, label: "Product Name" },
        { key: "price", sortable: true, label: "Product Price" },
        // { key: "price", sortable: true, label: "Inventory Price" },
        { key: "quantity", sortable: true, label: "Inventory Quantity" },
        { key: "warehouse", sortable: true, label: "In Warehouses" },
        { key: "machine", sortable: true, label: "In Machines" },
        { key: "vehicle", sortable: true, label: "In Vehicles" },
      ],
      showDismissibleAlert: false,
      active: true,
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.data.length
    }
  },
  created() {
    this.fetchData()
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.data.length
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    setActive(b) {
      this.active = b
    },
    refreshList() {
      // console.log(this.model_filter.location_id)
      inventoryService.getAll(this.model_filter).then(
        (data) => {
          // this.data = data.inventories
          let productObj = {}
          data.inventories.forEach((inventory) => {
            if (inventory.product.name != "") {
              let locations = productObj[inventory.product_id]
                ? productObj[inventory.product_id].locations
                : []
              locations.push(inventory)
              // console.log(
              //   "test1",
              //   productObj[inventory.product_id]
              //     ? productObj[inventory.product_id].quantity
              //     : 0 + inventory.quantity
              // )
              // console.log(
              //   "test2",
              //   locations.reduce(
              //     (inc, inventory) => (inc += inventory.quantity),
              //     0
              //   )
              // )
              productObj[inventory.product_id] = {
                id: inventory.product_id,
                sku: inventory.product.sku,
                name: inventory.product.name,
                price: inventory.product.price,
                quantity: locations.reduce(
                  (inc, inventory) => (inc += inventory.quantity),
                  0
                ),
                locations,
              }
            }
          })
          this.data = Object.values(productObj)
          this.loading = false
        },
        (err) => {
          this.error =
            "Failed to fetch inventory type data. Error : " +
            err +
            "  Dissmiss to refresh"
          this.loading = false
          this.showDismissibleAlert = true
        }
      )
    },
    fetchData() {
      this.error = null
      this.loading = true
      this.showDismissibleAlert = false
      this.refreshList()
      inventoryService.getAllLocation().then((data) => {
        data.locations.forEach((location) => {
          var location_type = ""
          var location_name = ""
          if (location.machine_id.Valid) {
            location_type = "machine"
            location_name = location.machine.serial_number
          } else if (location.warehouse_id.Valid) {
            location_type = "warehouse"
            location_name = location.warehouse.name
          } else if (location.vehicle_id.Valid) {
            location_type = "vehicle"
            location_name =
              location.vehicle.name +
              "(" +
              location.vehicle.vehicle_number +
              ")"
          }
          this.location_options[location_type].push({
            text: location_name,
            value: location.id,
          })
        })
        this.loading = false
      })
    },
    displayPrice(price) {
      if (Number.isInteger(price)) {
        return new Intl.NumberFormat('id-id', {
          style: 'currency',
          currency: 'IDR',
          maximumFractionDigits: 0,
        }).format(price)
      } else {
        return price
      }
    }
  },
};
</script>
<template>
  <div v-if="this.active">
    <b-alert
      variant="danger"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="fetchData"
    >
      {{ error }}
    </b-alert>
    <div class="card">
      <div class="card-body">
        <!-- <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">
        <template v-slot:button-content>
          <i class="mdi mdi-dots-vertical"></i>
        </template>
        <b-dropdown-item>Sales Report</b-dropdown-item>
        <b-dropdown-item>Export Report</b-dropdown-item>
        <b-dropdown-item>Profit</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
      </b-dropdown> -->

        <!-- <h4 class="card-title mb-4">(.*)</h4> -->
        <div class="row mt-4">
          <div class="col-sm-12 col-md-4">
            <div id="tickets-table_length" class="dataTables_length">
              <label class="d-inline-flex align-items-center">
                Show&nbsp;
                <b-form-select
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                ></b-form-select
                >&nbsp;entries
              </label>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div id="filter-modal-button" class="">
              <label class="d-inline-flex align-items-center">
                Filter&nbsp;
              </label>
              <b-button
                variant="outline-primary"
                class="ml-2"
                size="sm"
                v-b-modal.modal-filter
              >
                <i :class="` ri-filter-line`"></i>
              </b-button>
              <b-modal
                id="modal-filter"
                title="Location filter"
                ok-only
                @ok="refreshList"
              >
                <b-form-group
                  label="Location Type"
                  label-for="input-filter-type"
                >
                  <b-form-select
                    id="input-filter-type"
                    v-model="filter_type"
                    :options="location_type_options"
                    required
                  />
                </b-form-group>

                <b-form-group
                  label="Choose Location"
                  label-for="input-filter-location"
                >
                  <b-form-select
                    v-model="model_filter.location_id"
                    :options="location_options[filter_type]"
                    required
                  />
                </b-form-group>
              </b-modal>
            </div>
          </div>
          <!-- Search -->
          <div class="col-sm-12 col-md-4">
            <div
              id="tickets-table_filter"
              class="dataTables_filter text-md-right"
            >
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                  v-model="filter"
                  type="search"
                  class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
            </div>
          </div>
          <!-- End search -->
        </div>
        <div class="table-responsive">
          <b-table
            :items="data"
            :fields="fields"
            responsive="sm"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:cell(price)="row">
              {{ displayPrice(row.value) }}
            </template>
            <template v-slot:cell(warehouse)="item">
              {{
                item.item.locations
                  .filter((location) => location.location.warehouse_id.Valid)
                  .reduce((inc, inventory) => (inc += inventory.quantity), 0)
              }}
              in
              {{
                item.item.locations.filter(
                  (location) => location.location.warehouse_id.Valid
                ).length
              }}
              Warehouses
            </template>
            <template v-slot:cell(machine)="item">
              {{
                item.item.locations
                  .filter((location) => location.location.machine_id.Valid)
                  .reduce((inc, inventory) => (inc += inventory.quantity), 0)
              }}
              in
              {{
                item.item.locations.filter(
                  (location) => location.location.machine_id.Valid
                ).length
              }}
              Machines
            </template>
            <template v-slot:cell(vehicle)="item">
              {{
                item.item.locations
                  .filter((location) => location.location.vehicle_id.Valid)
                  .reduce((inc, inventory) => (inc += inventory.quantity), 0)
              }}
              in
              {{
                item.item.locations.filter(
                  (location) => location.location.vehicle_id.Valid
                ).length
              }}
              Vehicles
            </template>
          </b-table>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>