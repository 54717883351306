import { authHeader } from './auth-header';
import axiosInstance from "./client"

export const inventoryService = {
    getAll,
    getInventoryByID,
    getInventoryByLocationID,
    createOrUpdateInventory,
    getAllLocation,
    updateInventories,
};

function getAll(filter) {
    const requestOptions = {
        url : '/api/v1/user/inventory',
        method: 'GET',
        params: filter,
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
    //return fetch(`/api/v1/user/machine_type/` + machineTypeId, requestOptions).then(handleResponse);
}

function getInventoryByID(id) {
    const requestOptions = {
        url : `/api/v1/user/inventory/` + id,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
}

function getInventoryByLocationID(id) {
    const requestOptions = {
        url : `/api/v1/user/inventory/location/` + id,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
}

function createOrUpdateInventory(jsonData) {

    let formData = new FormData();

    for ( var key in jsonData ) {
        if (jsonData[key] != null)
            formData.append(key, jsonData[key]);
    }
    let rMethod = jsonData["id"] != null  ? "PUT" : "POST"
    const requestOptions = {
        url : `/api/v1/user/inventory`,
        method: rMethod,
        data: formData,
        headers: authHeader()
    };

    return axiosInstance(requestOptions);
}

function getAllLocation() {
    const requestOptions = {
        url : '/api/v1/user/location',
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
    //return fetch(`/api/v1/user/machine_type/` + machineTypeId, requestOptions).then(handleResponse);
}

function updateInventories(jsonData) {
    let rMethod = 'POST'
    let setHeader = authHeader()
    setHeader['Content-Type'] = 'application/json'
    const requestOptions = {
        url : `/api/v1/user/inventories`,
        method: rMethod,
        headers: setHeader,
        data: JSON.stringify(jsonData)
    };

    return axiosInstance(requestOptions);
}